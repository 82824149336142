import React, { useState } from "react";
import Header from "../../components/Header/Header";
import TextBox from "../../components/Boxes/Box";
import Footer from "../../components/Footer/Footer";
import school_image from "../../images/images/school_image.svg"
import '../../components/Buttons/Buttons.css'
import { useNavigate } from "react-router-dom";
import { sendMessage } from "../../components/ChatGPT";
import '../Homepage/Homepage.css'
import Popup from "../../components/PopUpWindows/Popupwindow";
import Popupwork from "../../components/PopUpWindows/Popupwork";




// import { View, Button, Modal, Text } from 'react'


// import '../compomemts/images/elements/school_image.svg'

// export default function Homepage() {  
//     return(
//         <div>
//             <Header />  
//             <h1>PlayRead</h1>
//         </div>       
//     )
// }

async function waitForResponse(text, setResponse, setHasCGPTResponded) {
    const reply_language = await sendMessage(text,"")
    let reply_language_2= reply_language 
    



    if (reply_language_2.toLowerCase().search("greek")>=0) {
        reply_language_2 = "Greek"
    }
    if (reply_language_2.toLowerCase().search("english")>=0) {
        reply_language_2="English"
    }
    const reply = await sendMessage(text,reply_language_2)
    setResponse(reply)
    setHasCGPTResponded(true)
    console.log(reply)
    console.log(reply_language_2)

}



function Homepage() {

    const navigate = useNavigate()
    
    const [response, setResponse] = useState("")
    const [text, setText] = useState("")
    
    const [hasCGPTResponded, setHasCGPTResponded] = useState(false)

    const [openPopup, setopenPopup] = useState(false);

    const [openPopupwork, setopenPopupwork] = useState(false);
  




    if(hasCGPTResponded) {
        navigate("/summary", {
            state: {
                response
            }
          })
    }

    const handleButton = () =>{
        //send messsage
            setopenPopupwork(true)
            console.log("test handled")
            waitForResponse(text,setResponse,setHasCGPTResponded);
            if(!setResponse){setopenPopupwork(false)}

    }

    return (
        <div style={{width:"100%"}}>
            
        <div className="flex-container">
            <div className="header">
                <Header />
            </div> 
           

            <div className="Homepage-main">
                <h2>Learn with fun</h2> 
                <h1>
                    <span>Simplify Learning </span>
                    <span>Amplify Fun</span>               
                </h1>
                <ol>
                    <li>Input your learning material</li>
                    <li>Read the key learning points</li>
                    <li>Quiz time! Play and test your knowledge</li>

                </ol>
                <TextBox text={text} setText={setText} className='box-st' />
                <div className="button-wrap">
                    <button className="button-1" onClick={text==="" ? () =>setopenPopup(true) :  handleButton}>Learn and Play</button>
                </div>
                <Popup open={openPopup} onClose={()=> setopenPopup(false)} />
                <Popupwork open={openPopupwork} onClose={()=> setopenPopupwork(false)} />
            </div>
            
                <img src={school_image} className="Homepage-img" size="100%"  align="top" alt="asd"/>
                
            <div className="footer"> 
                <Footer />
            </div>
             
        </div>        
        
        </div>   
    )
}

export default Homepage