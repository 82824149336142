import React from "react";
import { useState } from "react";
import allien1 from "../../images/elements/allien_1.svg"
import allien2 from "../../images/elements/allien_2.svg"
import allien3 from "../../images/elements/allien_3.svg";
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import "./Card.css"

import '../../components/Buttons/Buttons.css'


function Card () {  

    const { state } = useLocation();
    const Responces = state.Responces
    const navigate = useNavigate()
    const response = state.response

    console.log(Responces)

    const [Q_A, setQ_A] = useState("1Q")
    




    const handleButton_game_1Q_back = () => {
        
        navigate("/summary", {
            state: {
                Responces , response
            }

        })
    }

 

    const handleButton_game_1Q = () => {
      setQ_A("1Q");
      
        }


    const handleButton_game_1A = () => {
        setQ_A("1A");
        
      }


    const handleButton_game_2Q = () => {
        setQ_A("2Q");

        }


    const handleButton_game_2A = () => {
            setQ_A("2A");
            
        }

    const handleButton_game_3Q = () => {
        setQ_A("3Q");
        
        }

        const handleButton_game_3A = () => {
            setQ_A("3A");
            
        }


    const handleButton_game_end = () => {
        navigate("/", {

        })
    }






    return (
                <div className="card-points">                        

                    { Q_A==="1Q" &&
                        <div className="card">
                            <div className="card-title"><h1>Question 1</h1></div>
                            
                        <div className="card-card">
                            <img src={allien1} alt='allien 1'/>
                            <p style={{color:"#0077E9"}}>{Responces[1]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2" onClick={handleButton_game_1Q_back}>Back</button>
                            <button className="button-1" onClick={handleButton_game_1A}>Show the Answer</button>
                        
                        </div>
                        </div>
                        

                    }

                    { Q_A==="1A" &&
                        <div className="card">
                        <div className="card-title"><h1>Answer 1</h1></div>
                        
                        <div className="card-card" style={{backgroundColor:"#0077E9"}}> 
                            <img src={allien1} alt='allien 1'/>
                            <p style={{color:"#ffffff"}}>{Responces[0]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2" onClick={handleButton_game_1Q}>Back</button>
                            <button className="button-1"  onClick={handleButton_game_2Q}>Next Question</button>
                        
                        </div>
                        </div>

                    }

                    { Q_A==="2Q" &&
                        <div className="card">
                        <div className="card-title"><h1>Question 2</h1></div>
                            
                        <div className="card-card">
                            <img src={allien2} alt='allien 2'/>
                            <p style={{color:"#4D8075"}}>{Responces[3]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2" onClick={handleButton_game_1A}>Back</button>
                            <button className="button-1"  onClick={handleButton_game_2A}>Show the Answer</button>
                        
                        </div>
                        </div>

                    }
                    
                    { Q_A==="2A" &&
                        <div className="card">
                        <div className="card-title"><h1>Answer 2</h1></div>
                        
                        <div className="card-card" style={{backgroundColor:"#4D8075"}}>
                            <img src={allien2} alt='allien 2'/>
                            <p style={{color:"#ffffff"}}>{Responces[2]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2"  onClick={handleButton_game_2Q}>Back</button>
                            <button className="button-1"  onClick={handleButton_game_3Q}>Next Question</button>
                        
                        </div>
                        </div>

                    }


                    { Q_A==="3Q" &&
                        <div className="card">
                        <div className="card-title"><h1>Question 3</h1></div>
                        
                        <div className="card-card">
                            <img src={allien3} alt='allien 3'/>
                            <p style={{color:"#887B06"}}>{Responces[5]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2" onClick={handleButton_game_2A}>Back</button>
                            <button className="button-1" onClick={handleButton_game_3A}>Show the Answer</button>
                        
                        </div>
                        </div>

                    }
                    
                    { Q_A==="3A" &&
                        <div className="card">
                        <div className="card-title"><h1>Answer 3</h1></div>
                        
                        <div className="card-card" style={{backgroundColor:"#887B06"}}>
                            <img src={allien3} alt='allien 3'/>
                            <p style={{color:"#ffffff"}}>{Responces[4]}</p>
                            
                        </div>
                        <div className="flex-card-wrap">
                        
                            <button className="button-2" onClick={handleButton_game_3Q}>Back</button>
                            <button className="button-1" onClick={handleButton_game_end}>Back to homepage</button>
                        
                        </div>
                        </div>

                    }


                
                </div>

      
    );
  };
  
  export default Card;

