// Replace 'YOUR_API_KEY' with your actual API key
const apiKey = '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';

// Function to send a message to ChatGPT and receive a response
export async function sendMessage(message,language) {
  const url = 'https://api.openai.com/v1/chat/completions';


  if (language==="") {
    
    message = "Use the following step-by-step instructions to respond to user inputs."+
            'Step 1 - The user will provide you with text in triple quotes'+
            'Step 2 - Find the language that is written the text.'+
            '"""'+message+'"""';
            console.log("language search")
            
  }  else {
              message = "Use the following step-by-step instructions to respond to user inputs. Use " + language + " language to respond"+
                    'Step 1 - The user will provide you with text in triple quotes'+
                    'Step 2 - Summarize the text in three bullet points which each bullet point to be arround 75 words. Use as a prefix the letter "R" and the number of the bullet point.' +   
                    'Step 3 - Create only one question for each bullet point. Use as a prefix the letter "Q" and the number of the Question.' +            
                    '"""'+message+'"""';
                    console.log("Summary")
                    
    }



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${apiKey}`
  };

  const body = {
    'model': 'gpt-3.5-turbo',
    'messages': [{'role': 'system', 'content': 'You are a helpful assistant.'}, {'role': 'user', 'content': message}]
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  });

  const data = await response.json();
  const reply = data.choices[0].message.content;

  console.log(reply)
  

  return reply;
}

// Example usage
// const inputField = document.getElementById('inputField');
// const outputField = document.getElementById('outputField');

// async function handleUserInput() {
//   const userInput = inputField.value;
//   const reply = await sendMessage(userInput);
//   outputField.textContent = reply;
// }

// inputField.addEventListener('keydown', async (event) => {
//   if (event.key === 'Enter') {
//     await handleUserInput();
//     inputField.value = '';
//   }
// });
