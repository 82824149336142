import React from "react";
import './Footer.css'

export default function Footer() {  
    return(
        <div className="footer-nav">
            <small >© 2024 PlayRead. All rights reserved.</small>
        </div>


    )
    
}