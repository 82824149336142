import React from "react";
import '../PopUpWindows/Popupwindow.css'

const   Popup =({open,onClose}) => {
    
    if(!open) return null
    return (
        
        <div onClick={onClose} className='overlay'>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className='modalContainer'
        >
          
          <div className='modalRight'>

            <div className='content'>
              
              <h4>Input text to continue!</h4>
              
            </div>
            <div className='btnContainer'>

              <button className='button-1' onClick={onClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Popup