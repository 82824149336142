import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Card from "./Card"
import { useLocation } from "react-router-dom";
import "./Game.css"



// export default function Homepage() {  
//     return(
//         <div>
//             <Header />  
//             <h1>PlayRead</h1>
//         </div>       
//     )
// }




function Game() {
    
    const { state } = useLocation();
    const Responces = state.Responces

    console.log(Responces)
    // const response = state.response


    return (
        <div style={{width:"100%"}}>
        <div className="flex-container-game">
            <div className="header"><Header /></div>
            
            <div className="flex-container-game-card">

                <Card />

            </div>
                        
            <div className="footer"><Footer /></div>
            
            </div>

        
        </div>   
    )
}


export default Game