import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import allien1 from "../../images/elements/allien_1.svg"
import allien2 from "../../images/elements/allien_2.svg"
import allien3 from "../../images/elements/allien_3.svg"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import '../../components/Buttons/Buttons.css'
import './Summary.css'




// export default function Homepage() {  
//     return(
//         <div>
//             <Header />  
//             <h1>PlayRead</h1>
//         </div>       
//     )
// }

function answers(response) {
    
    

    const Ans_ = [];
    const Responces = [];
    

    for (let i = 1; i <= 3; i++) {
        Ans_.push(response.search("R"+i+"."));
        Ans_.push(response.search("Q"+i+"."));       
        
      }
    

    for (let i = 0; i <= 5; i++) {

        let k=response.length;
        
        for (let j = 0; j <= 5; j++) {

            


            if(Ans_[j]<k && Ans_[j]>Ans_[i]){

                k=Ans_[j];
            }

            
        
        }

        Responces.push(response.slice(Ans_[i]+4,k))
        
        
      }    
      console.log(Responces)

    return(Responces)



}

function Summary() {

    const navigate = useNavigate()
    

    const handleButtonback = () =>{
        navigate("/", {
            state: {
                
            }
          })
    }

    const handleButtonnext = () =>{
        navigate("/game", {
            state: {
                Responces, response
            }
          })
    }

    
    const { state } = useLocation();
    const response = state.response
    
    console.log({state:response})
    console.log(response)
   
    const Responces = answers(response)
        
    

    return (
        <div style={{width:"100%"}}>
        
            <div className="flex-container_2">
                <div className="header">
                    <Header />
                </div>
                
                <div className="summary-title">
                    <h1>These are three points to learn</h1>
                </div>
                <div >
                <div className="flex-container_">   
                    <ul className="summary-points">
                        <li style={{backgroundColor : "#0077E9"}}>
                            <div>
                                <img src={allien1} alt='allien 1'/>
                            </div>
                            <div className="summary-points-txt">
                                <p>{Responces[0]}</p>
                            </div>
                            
                            
                            
                            
                        </li>
                        <li style={{backgroundColor : "#4D8075"}}>
                            <div>
                                <img src={allien2} alt='allien 2'/>
                            </div>
                            <div className="summary-points-txt">
                                <p>{Responces[2]}</p>
                            </div>
                            
                            
                        </li>
                        <li style={{backgroundColor : "#887B06"}}> 
                            <div>
                                <img src={allien3} alt='allien 2'/>
                            </div>
                            <div className="summary-points-txt">
                                <p>{Responces[4]}</p>
                            </div>
                            
                        </li>
                    </ul>

                    <div className="flex-summary-nav">
                            <div > 
                                <button className="button-2" onClick={handleButtonback}>Back</button>
                            </div>
                            
                            <div>
                                <button className="button-1" onClick={handleButtonnext}>Let's Play</button>
                            </div>
                            
                    </div>       
                                
                    </div>

                </div>

                <div className="footer">
                    <Footer />
                </div>
                
            </div>
        
        </div>   
    )
}

export default Summary