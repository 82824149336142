import React from "react";
import './Header.css'
import logotype from '../../images/logo/logotype.png'


export default function Header() {  
    return(
        <div className="header--nav"> 
              
            <a href="/"><img src={logotype} alt='logo' style={{width : "280px"}}/></a>
            
        </div>

    )




}


