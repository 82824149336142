import './App.css';
import { Route, Routes} from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import Summary from './pages/Summary/Summary';
import Game from './pages/Games/Game.js'





function App() {
  return (
    <div>

    {/* <nav>
        <ul>
          <Link to="/" class="home">
            Home
          </Link>
          <Link to="/blah" class="list">
            Blah
          </Link>
          <Link to="/test" class="list">
            test
          </Link>
        </ul>
      </nav> */}

      <Routes>
        <Route path="/homepage" element={<Homepage />} />
        
        <Route path="" element={<Homepage />} />
        <Route path="/Summary" element={<Summary />} />
        <Route path="/game" element={<Game />} />        

      </Routes>
      

    </div>
  );
}

export default App;
