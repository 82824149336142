import React from "react";
import '../PopUpWindows/Popupwindow.css'

const   Popupwork =({open,onClose}) => {
    
  if(!open) return null
  return (
      
      <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
        
        <div className='modalRight'>

          <div className='content'>
            
            <h4>We are making your game!</h4>
            <p>It will take a moment...</p>
            
          </div>
          <div className='btnContainer'>


          </div>
        </div>
      </div>
    </div>
  )
}

export default Popupwork