import React from 'react';
import './Box.css'

const TextBox = (args) => {  
    const handleChange = (event) => {
      args.setText(event.target.value);
    };
  
    return (
      <div className='box-st'>
        <textarea
          
          value={args.text}
          onChange={handleChange}          
          placeholder="Input the text that you want to learn"
          
        />
      </div>
    );
  };
  
  export default TextBox;